import { acceptHMRUpdate, defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { myyApi } from '@/api/myy';
import type { User } from '@/models/User';
import type { AuthToken } from '@/types/AuthToken';
import { HTTPError } from 'ky';
import type { AuthData, UserData } from '@/api/myy/modules/auth';
import { toast } from '@/helpers/toast';
import { CookieStorage } from '@/stores/storage/CookieStorage';

export const useAuthStore = defineStore(
  'auth',
  () => {
    const user = ref<User | null>(null);
    const token = ref<AuthToken | null>(null);

    const isAuthenticated = computed(() => !!user.value);

    async function fetchUserFromToken() {
      if (!token.value) return null;

      try {
        user.value = await myyApi.auth.view();
      } catch (error) {
        if (!(error instanceof HTTPError) || error.response.status !== 401) console.error(error);

        token.value = null;
      }

      return user.value;
    }

    async function authenticateTemp(data: UserData) {
      try {
        const response = await myyApi.auth.authenticateTemp(data);
        user.value = response.user;
        token.value = response.token;
        return true;
      } catch (error) {
        console.error(error);
        toast.error('Failed to create user. Please try again soon or contact us.');
        return false;
      }
    }

    async function authenticate(data: AuthData) {
      try {
        const response = await myyApi.auth.verifyOtp(data);
        user.value = response.user;
        token.value = response.token;
        return true;
      } catch (error) {
        console.error(error);
        toast.error('Failed to create user. Please try again soon or contact us.');
        return false;
      }
    }

    return { user, token, isAuthenticated, fetchUserFromToken, authenticateTemp, authenticate };
  },
  {
    persist: {
      storage: new CookieStorage({ expires: 14 }),
      pick: ['token'],
      afterHydrate(context) {
        context.store.fetchUserFromToken();
      },
    },
  }
);

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
