import { acceptHMRUpdate, defineStore, type StateTree } from 'pinia';
import type { RawEntry } from '@/types/RawEntry';
import { ref } from 'vue';
import { Entry } from '@/models/Entry';
import { useActivity } from '@/composables/useActivity';
import { myyApi } from '@/api/myy';
import { CookieStorage } from '@/stores/storage/CookieStorage';
import { useRoute } from 'vue-router';

export const useEntryStore = defineStore(
  'entry',
  () => {
    const rawEntry = ref<RawEntry>({});
    const entry = ref<Entry | null>(null);
    const ageGateDateOfBirth = ref<string | null>(null);

    async function submitEntry(transformedData: RawEntry) {
      const data = {
        activity: useActivity()._id,
        data: transformedData,
      };

      entry.value = await myyApi.entries.store(data);
      return entry.value;
    }

    async function loadEntry(): Promise<Entry | null> {
      const route = useRoute();
      const routeAlias = route.query.e;

      // if (routeAlias && typeof routeAlias === 'string' && entry.value?.alias !== routeAlias) //This line prevents refreshing the entry value.
      if (routeAlias && typeof routeAlias === 'string') {
        entry.value = await myyApi.entries.viewByAlias(routeAlias);
      }

      return entry.value;
    }

    return { rawEntry, entry, ageGateDateOfBirth, submitEntry, loadEntry };
  },
  {
    persist: {
      storage: new CookieStorage(),
      serializer: {
        serialize: JSON.stringify,
        deserialize: (data: string): StateTree => {
          const json = JSON.parse(data);

          if (json.entry) json.entry = Entry.fromApi(json.entry);

          return json;
        },
      },
    },
  }
);

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useEntryStore, import.meta.hot));
