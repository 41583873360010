<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';
import type { UserData } from '@/api/myy/modules/auth';
import { provide, ref } from 'vue';
import type { FormInstance } from 'element-plus';
import type { RawEntry } from '@/types/RawEntry';
import type { Form } from '@/models/form/Form';
import { cloneDeep } from 'lodash-es';
import { toast } from '@/helpers/toast';
import { ValidationError } from '@/types/ValidationError';
import { myyApi } from '@/api/myy';
import { FieldType } from '@/models/form/FormField';
import { HTTPError } from 'ky';
import type { APIValidationError } from '@/api/myy/types';

const props = defineProps<{
  form: Form;
  disabled: boolean;
}>();

const emit = defineEmits<{
  startSubmit: [];
  error: [error: unknown];
  submit: [data: RawEntry];
}>();

const model = defineModel<RawEntry>({ required: true });
provide('formEntry', model);
provide('form', props.form);

const authStore = useAuthStore();
const formElement = ref<FormInstance>();

async function submitForm() {
  if (props.disabled) return;

  const handleSubmit = async () => {
    console.info('Submitting form');
    const data = cloneDeep(model.value);

    // Validate + run validate pipeline
    await formElement.value!.validate().catch(() => {
      throw new ValidationError('Please fix your submission and retry.');
    });
    await props.form.runValidations();
    console.info('Validation stage completed');

    // Run pre-authenticate process pipeline
    await props.form.runStage('preAuthenticate', data);
    console.info('Pre-authenticate stage completed');

    // Authenticate
    const authData: UserData = data.user;
    // If user isn't logged in OR they are logged in as a temporary user, authenticate using API
    if (!authStore.isAuthenticated || authStore.user!.isTemporary) await authStore.authenticateTemp(authData);
    // Otherwise, just update the user's profile using API
    else await myyApi.auth.updateProfile(authData);
    console.info('Authenticated');

    // Run process pipeline
    await props.form.runStage('postAuthenticate', data);
    console.info('Post-authenticate stage completed');

    return data;
  };

  try {
    emit('startSubmit');
    const data = await handleSubmit();
    emit('submit', data);
  } catch (error) {
    console.error(error);

    if (error instanceof ValidationError) {
      if (error.showToast) toast.error(error.message);
    } else if (error instanceof HTTPError) {
      if (error.response.status >= 500) toast.error('An unknown error occurred. Please try again or contact us.');
      else {
        const errorJson = (await error.response.json()) as APIValidationError;
        errorJson.errors.forEach((validationError) => toast.error(validationError.message));
      }
    } else {
      toast.error('Please fix your submission and retry.');
    }

    emit('error', error);
  }
}
</script>

<template>
  <div class="text-center my-4 text-sm">All <span class="req-asterisk">*</span> fields are mandatory.</div>

  <el-form
    ref="formElement"
    :model="model"
    :rules="form.activeRules"
    :disabled="disabled"
    label-position="top"
    :validate-on-rule-change="false"
  >
    <el-row v-for="(step, idx) in form.steps" :key="idx" :gutter="20" justify="center">
      <el-col
        v-for="(field, fIdx) in step.fields"
        v-show="field.shouldDisplay"
        :key="fIdx"
        :span="field.display.spanMobile"
        :sm="field.display.spanDesktop"
        :class="{ 'z-50': field.type === FieldType.MOBILE }"
        class="mb-1.5"
      >
        <component :is="field.component" :field="field" @submit-form="submitForm" />
      </el-col>
    </el-row>
  </el-form>

  <div class="mt-8 flex justify-center">
    <el-button type="primary" class="btn-big" :disabled="disabled" @click="submitForm">Submit</el-button>
  </div>
</template>

<style>
.req-asterisk {
  color: var(--myy-color-form-required-indicator);
  @apply text-base;
}
</style>
